.App {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 1rem !important;
  background-color: #ecdccc;
  font-family: 'Times New Roman', Times, serif;
  /* line-height: 2rem; */
}

.noto {
  font-family: 'Noto Serif';
  font-weight: 600;
  letter-spacing: 0.3px;
  color: black;
  font-size: 1.55rem;
}

.times {
  font-family: 'Times New Roman', Times, serif;
  /* letter-spacing: 0.2px; */
  color: black;
  font-size: 1.55rem;
}

.times > .title {
  font-weight: 500;
  /* font-size: 3rem; */
}

.times > .iridology-caption {
  font-weight: 600;
}

.red { color: #e4454b; }
.row { max-width: 100vw; }
.rightCol { border-left: 1px black dashed; }
.bottomborder { border-bottom: 1px black dashed; }




@media all and (min-width: 1000px) {
  .banner {
    height: 40vh;
    padding-left: 4%;
  }

  .herbal {
    width: 15vw;
  }

  .leech {
    width: 15vw;
  }

  .bemer {
    width: 35.5vw;
  }


}

@media all and (min-width: 800px) and (max-width: 999px) {
  html, body {
    overflow-x: hidden;
  }

  .banner {
    height: 20vh;
    padding-left: 6%;
  }

  .iridology-h1 {
    font-size: 1.7rem;
  }

  .iridology-caption {
    font-size: 1.0rem;
  }

  .App {
    font-size: 1.2rem;
  }

  .herbal {
    height: 10vh;
  }

  .leech {
    height: 10vh;
  }

  .bemer {
    height: 15vh;
  }
}

@media all and (min-width: 200px) and (max-width: 799px) {
  html, body {
    overflow-x: hidden;
  }

  .banner {
    height: 20vh;
    padding-left: 6%;
  }

  .iridology-h1 {
    font-size: 1.7rem;
  }

  .iridology-caption {
    font-size: 1.0rem;
  }

  .App {
    font-size: 1.2rem;
    /* font-size: 1.4rem; */
  }

  .herbal {
    height: 10vh;
  }

  .leech {
    height: 10vh;
  }

  .bemer {
    height: 15vh;
  }
}

.inline-caption {
  display: flex; 
  align-items: flex-end;
}